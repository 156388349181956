<template>
  <div class="container">
    <br>
    <el-table
      :data="list"
      border
      fit
      style="width: 80%"
    >
      <el-table-column
        :label="$t('footer.relation.title')"
      >
        <template slot-scope="scope">
          <el-link target="_blank" :href="scope.row.url">{{ scope.row.label }}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <br>
  </div>
</template>

<script>
export default {
  name: 'Relation',
  computed: {
    list: function() {
      return [
        { label: this.$t('footer.relation.link1.label'), url: this.$t('footer.relation.link1.url') },
        { label: this.$t('footer.relation.link2.label'), url: this.$t('footer.relation.link2.url') },
        { label: this.$t('footer.relation.link3.label'), url: this.$t('footer.relation.link3.url') },
        { label: this.$t('footer.relation.link4.label'), url: this.$t('footer.relation.link4.url') },
        { label: this.$t('footer.relation.link5.label'), url: this.$t('footer.relation.link5.url') },
        { label: this.$t('footer.relation.link6.label'), url: this.$t('footer.relation.link6.url') },
        { label: this.$t('footer.relation.link7.label'), url: this.$t('footer.relation.link7.url') },
        { label: this.$t('footer.relation.link8.label'), url: this.$t('footer.relation.link8.url') },
        { label: this.$t('footer.relation.link9.label'), url: this.$t('footer.relation.link9.url') },
        { label: this.$t('footer.relation.link10.label'), url: this.$t('footer.relation.link10.url') },
        { label: this.$t('footer.relation.link11.label'), url: this.$t('footer.relation.link11.url') },
        { label: this.$t('footer.relation.link12.label'), url: this.$t('footer.relation.link12.url') },
        { label: this.$t('footer.relation.link13.label'), url: this.$t('footer.relation.link13.url') },
        { label: this.$t('footer.relation.link14.label'), url: this.$t('footer.relation.link14.url') }
      ]
    }
  }
}
</script>

<style scoped>

</style>
